import React from "react";

import './bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import '../css/app.css';

import '../scss/index.scss';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/700.css";

import * as Sentry from "@sentry/react";

Sentry.init({
    // dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    // this value gets inlined and is the same in all envs - hardcode it for now at least
    dsn: 'https://136065c5135232fb93863ccb91297c07@o4506508107382784.ingest.us.sentry.io/4506717058629632',
});

const appName = import.meta.env.APP_NAME || 'SES Hubs';

createInertiaApp({
    title: (title) => `${title}  : ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#F0FFFF',
    },
});
